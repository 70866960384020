import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import playstore from "../../../assets/images/playstore.webp";
import AppleStore from "../../../assets/images/appstore.webp";
import MobileAPPBG from "../../../assets/images/socSignupPage/smartBAPPMobile.png";
import SmartbAPPBG from "../../../assets/images/socSignupPage/smartBAPP.png";
import { Config } from "src/helpers/context";

const SmartBAppNow = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  return (
    <>
      <Box className="smartB-wrap-app-section">
        <Box className="smartB-wrap-app-container">
          {screenWidth > 1023 ? (
            <Box className="container-wrap">
              <Box className="smartB-wrap">
                <Typography className="download-app-text">
                  <span className="highlight-text">Download</span> the SmartB
                  app now
                </Typography>
                <Typography className="download-details">
                  The SmartB app is available on the Apple App Store and Google
                  Play Store. Access every SmartB feature on an app for a more
                  powerful experience.
                </Typography>
                <Box className="playstore-wrap">
                  <Box className="link-wrap playstore-logo">
                    <a
                      // href={
                      //   Config.release == "AU"
                      //     ? "https://play.google.com/store/apps/details?id=au.com.smartb"
                      //     : "https://play.google.com/store/apps/details?id=in.com.smartb"
                      // }
                      href="https://drive.google.com/file/d/1chFmUXj7KhxoPeAouhIdyzPMlm0mGVC-/view"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={playstore}
                        alt="playstore"
                        className="playstore-img"
                      />
                    </a>
                  </Box>
                  <Box className="link-wrap">
                    <a
                      href={
                        Config.release == "AU"
                          ? "https://apps.apple.com/au/app/smartb-app/id1672189237"
                          : "https://apps.apple.com/us/app/smartb-india/id6479695004"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={AppleStore}
                        alt="Apple-store"
                        className="playstore-img"
                      />
                    </a>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className="container-wrap-mobile">
              <Box className="smartB-wrap">
                <Typography className="download-app-text">
                  <span className="highlight-text">Download</span> the SmartB
                  app now
                </Typography>
                <Typography className="download-details">
                  The SmartB app is available on the Apple App Store and Google
                  Play Store. Access every SmartB feature on an app for a more
                  powerful experience.
                </Typography>
                <Box className="playstore-wrap">
                  <Box className="link-wrap playstore-logo">
                    <a
                      // href={
                      //   Config.release == "AU"
                      //     ? "https://play.google.com/store/apps/details?id=au.com.smartb"
                      //     : "https://play.google.com/store/apps/details?id=in.com.smartb"
                      // }
                      href="https://drive.google.com/file/d/1chFmUXj7KhxoPeAouhIdyzPMlm0mGVC-/view"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={playstore}
                        alt="playstore"
                        className="playstore-img"
                      />
                    </a>
                  </Box>
                  <Box className="link-wrap">
                    <a
                      href={
                        Config.release == "AU"
                          ? "https://apps.apple.com/au/app/smartb-app/id1672189237"
                          : "https://apps.apple.com/us/app/smartb-india/id6479695004"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={AppleStore}
                        alt="Apple-store"
                        className="playstore-img"
                      />
                    </a>
                  </Box>
                </Box>
                <Box className="smartb-app-bg">
                  <img
                    src={screenWidth > 559 ? SmartbAPPBG : MobileAPPBG}
                    alt="mobile"
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SmartBAppNow;
